import { TO_DOS_FIELDS_NAMES } from '@constants/todosData';

export const NOTES_AND_HISTORY_INPUT_NAMES = {
  communicateWithContact: TO_DOS_FIELDS_NAMES.communicateWithContact,
  noteContactId: TO_DOS_FIELDS_NAMES.noteContactId,
  noteId: TO_DOS_FIELDS_NAMES.noteId,
  notes: TO_DOS_FIELDS_NAMES.taskNotes,
  noteText: TO_DOS_FIELDS_NAMES.noteText,
  noteType: TO_DOS_FIELDS_NAMES.noteType,
  tagId: TO_DOS_FIELDS_NAMES.noteTagId,
  tags: TO_DOS_FIELDS_NAMES.noteTags,
  tagValue: TO_DOS_FIELDS_NAMES.noteTagValue,
  title: TO_DOS_FIELDS_NAMES.noteTitle,
};

export const LABEL_WIDTH = {
  common: 123,
  rightColumn: 112,
  taskForAssigner: 240
};
