import { MapTypeNamesToValues } from '@/shared/types/commonTypes';
import { ClientOpportunityStatusType, SavedClientOpportunityItem } from '@/shared/types/salesCycleOpportunity';

import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import { COLORS } from '@constants/colors';
import { SALES_PIPELINE_BASE_STATUSES } from '@constants/salesPipeline';
import { NoteItemFieldsNames } from '@modules/AccountsAndPlansPopup/types';
import { NoteItem } from '@/shared/types/note';

export const OPPORTUNITY_BASE_STATUSES = SALES_PIPELINE_BASE_STATUSES;

export const OPPORTUNITY_STATUSES = {
  ...OPPORTUNITY_BASE_STATUSES,
  followingUp: 'Following-Up',
  forNextMeeting: 'For Next Meeting',
  inactive: 'Inactive'
} as const;

export const OPPORTUNITY_STATUS_OPTIONS = optionsFromValue(Object.values(OPPORTUNITY_STATUSES));

export const OPPORTUNITY_STATUSES_COLOR_MAP: Record<ClientOpportunityStatusType, string> = {
  [OPPORTUNITY_STATUSES.dQ]: COLORS.yellow,
  [OPPORTUNITY_STATUSES.dead]: COLORS.red,
  [OPPORTUNITY_STATUSES.lost]: COLORS.purple,
  [OPPORTUNITY_STATUSES.won]: COLORS.green,
  [OPPORTUNITY_STATUSES.active]: COLORS.blue,
  [OPPORTUNITY_STATUSES.followingUp]: COLORS.followingUp,
  [OPPORTUNITY_STATUSES.forNextMeeting]: COLORS.pink,
  [OPPORTUNITY_STATUSES.inactive]: COLORS.search,
};

export const OPPORTUNITY_SALE_TYPES = {
  TBD: 'TBD',
  AUMFeeBased: 'AUM (Fee-Based)',
  RILAFeeBased: 'RILA (Fee-Based)',
  FIAFeeBased: 'FIA (Fee-Based)',
  FIAComm: 'FIA(Comm.)',
  MYGAFeeBased: 'MYGA (Fee-Based)',
  VAFeeBased: 'VA (Fee-Based)',
  LifeInsurance: 'Life Insurance',
  LTCHybrid: 'LTC Hybrid',
  Medicare: 'Medicare',
  Other: 'Other'
} as const;

export const OPPORTUNITY_SALE_TYPES_OPTIONS = optionsFromValue(Object.values(OPPORTUNITY_SALE_TYPES));

export const OPPORTUNITY_STAGES = {
  identified: 'Identified',
  interested: 'Interested',
  inProgress: 'In Progress',
  paperwork: 'Paperwork',
  transfers: 'Transfers',
  fundedDelivery: 'Funded/Delivery'
} as const;

export const OPPORTUNITY_STAGES_OPTIONS = optionsFromValue(Object.values(OPPORTUNITY_STAGES));

export const OPPORTUNITY_MEETING_STATUSES = {
  ScheduleReschedule: 'Schedule/Reschedule',
  NoShowReschedule: 'No Show Reschedule',
  ShortHold: 'Short Hold',
  SendFollowUp: 'Send Follow-Up'
} as const;

export const OPPORTUNITY_MEETING_STATUS_OPTIONS = optionsFromValue(Object.values(OPPORTUNITY_MEETING_STATUSES));

export const OPPORTUNITY_FIELD_NAMES: MapTypeNamesToValues<SavedClientOpportunityItem> = {
  amount: 'amount',
  estCloseDate: 'estCloseDate',
  id: 'id',
  keyNote: 'keyNote',
  contactId: 'contactId',
  meetingStatus: 'meetingStatus',
  nextAppointmentDate: 'nextAppointmentDate',
  notes: 'notes',
  onboarding: 'onboarding',
  opportunityOpenDate: 'opportunityOpenDate',
  opportunityStatus: 'opportunityStatus',
  saleType: 'saleType',
  sourceId: 'sourceId',
  stage: 'stage',
  tasks: 'tasks',
  wonDate: 'wonDate'
} as const;

export const OPPORTUNITY_NOTES_FIELDS_ITEM_NAMES: MapTypeNamesToValues<Required<NoteItem>> = {
  communicateWithContact: 'communicateWithContact',
  noteContactId: 'noteContactId',
  noteId: 'noteId',
  noteText: 'noteText',
  noteType: 'noteType',
  tags: 'tags',
  title: 'title',
};

export const OPPORTUNITY_FILTER_NAMES = {
  users: 'officePrimaryAdvisor',
  search: 'name',
  saleType: 'saleType',
  opportunityStatus: 'opportunityStatus',
  meetingStatus: 'meetingStatus',
  stage: 'stage',
} as const;


