import React from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useStore } from '@store';
import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';

import NotesAndHistory from '@modules/NotesAndHistory';

import { NotesAndHistoryInputNames } from '@modules/NotesAndHistory/types';
import { FormFieldsValues } from '@modules/AccountsAndPlansPopup/types';

import {
  NOTES_FIELDS_ITEM_NAMES,
  NOTES_NAMES,
  TAG_FIELDS_ITEM_NAMES,
} from '@modules/AccountsAndPlansPopup/data/fieldNames';

import { Wrapper } from './styles';

import { AccountAndPlansNotesAndHistoryProps } from './types';


const InputNames: NotesAndHistoryInputNames ={
  communicateWithContact: NOTES_FIELDS_ITEM_NAMES.communicateWithContact,
  noteContactId: NOTES_FIELDS_ITEM_NAMES.noteContactId,
  noteId: NOTES_FIELDS_ITEM_NAMES.noteId,
  notes: NOTES_NAMES.accountPlansNotes,
  noteText: NOTES_FIELDS_ITEM_NAMES.noteText,
  noteType: NOTES_FIELDS_ITEM_NAMES.noteType,
  tagId: TAG_FIELDS_ITEM_NAMES.id,
  tags: NOTES_FIELDS_ITEM_NAMES.tags,
  tagValue: TAG_FIELDS_ITEM_NAMES.value,
  title: NOTES_FIELDS_ITEM_NAMES.title,
};

export const AccountAndPlansNotesAndHistory = observer(({
  deleteConfirmationText
}: AccountAndPlansNotesAndHistoryProps) => {
  const { profile } = useStore().SettingsStore;
  const accountsAndPlansLocalStore = useAccountAndPlansPopupLocalStore();
  const {
    isNotesPanelDisabled,
    linkedContactData,
    notesAndHistoryStore,
    notesUtilsStore,
    predefinedTags,
  } = accountsAndPlansLocalStore;

  const { control } = useFormContext<FormFieldsValues>();

  return (
    <Wrapper>
      {/* TODO: note refactoring */}
      {/* @ts-ignore */}
      <NotesAndHistory
        $maxHeight={ '765px' }
        control={ control }
        currentUser={ profile }
        deleteConfirmationText={ deleteConfirmationText }
        disableAddNoteTooltipText={ 'To create a Note you need to publish Account Name and select Linked Contact' }
        instanceStore={ notesAndHistoryStore }
        isAddNoteBtnDisabled={ isNotesPanelDisabled }
        linkedContactData={ linkedContactData }
        names={ InputNames }
        noteContactOptions={ notesUtilsStore.noteContactOptions }
        onAddEditEnd={ notesUtilsStore.toggleNotesPanelStateOff }
        onAddEditStart={ notesUtilsStore.toggleNotesPanelStateOn }
        predefinedTags={ predefinedTags }
        title={ 'Account Notes & History' }
      />
    </Wrapper>
  );
});
