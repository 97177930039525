import React, { useMemo } from 'react';

import Label from '@components/Label';
import SaleCycleStatusOption from '@modules/SalesPipline/SaleCycleStatusOption';
import SalesClientOpportunitiesStatusOption from '@modules/SalesPipline/SalesClientOpportunitiesStatusOption';
import TextWithTooltip from '@components/TextWithTooltip';
import UserItem from '@components/NewUserItem';

import { getFullName } from '@/shared/utils/fetchAutocompleteUtils';
import { getUTCFormattedString } from '@/shared/utils/getUTCFormattedString';

import { COLORS } from '@constants/colors';
import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';
import { LINKED_ITEM_TYPES } from '@constants/linkedItem';
import { MONTH_DAY_YEAR_BACKSLASH } from '@constants/dateFormats';

import {
  AmountColumn,
  AssignedToColumn,
  EntityNameWrapper,
  NameColumn,
  StageColumn,
  StatusColumn,
  TypeColumn
} from './styles';

import { LinkedItemOptionProps } from './types';

const USER_ITEM_PROPS = {
  withCoreClassification: true,
  isHouseholdInTwoLines: true,
  labelWidth: 150,
  innerPadding: '0'
};

export const LinkedItemOption = ({
  data,
  isEntity = false
}: LinkedItemOptionProps) => {

  const { linkedContactType } = data;

  const accountAndPolicyAmount = (
    linkedContactType === LINKED_ITEM_TYPES.account ||
    linkedContactType === LINKED_ITEM_TYPES.policy) &&
    data.value ? formatNumberToAmount(data.value) : '-';

  const salesCycleAndOpportunityAmount = (
    linkedContactType === LINKED_ITEM_TYPES.opportunity ||
    linkedContactType === LINKED_ITEM_TYPES.salesCycle) &&
    data.amount ? formatNumberToAmount(data.amount) : '-';  

  const feeAmount = linkedContactType === LINKED_ITEM_TYPES.plan && data.feeAmount ?
    formatNumberToAmount(data.feeAmount) : '-';

  const dueDate = useMemo(() => {
    if(linkedContactType === LINKED_ITEM_TYPES.task) {
      const actualDate = data.isAssigned ? data.reviewDate : data.dueDate;
      return actualDate ? getUTCFormattedString(actualDate, MONTH_DAY_YEAR_BACKSLASH) : '-';    
    }
    return '-';
  }, [data]);

  return (
    <>
      { isEntity && 
        <NameColumn>
          { linkedContactType === LINKED_ITEM_TYPES.opportunity || linkedContactType === LINKED_ITEM_TYPES.salesCycle ?
            <UserItem
              user={ data.contactData }
              { ...USER_ITEM_PROPS }
            /> :
            <EntityNameWrapper>
              <TextWithTooltip text={ data.name }/>
            </EntityNameWrapper>
          }
        </NameColumn>
      }
      { !isEntity &&
        <NameColumn>
          <UserItem
            user={ data.contactData }
            { ...USER_ITEM_PROPS }
          />
        </NameColumn>
      }
      <TypeColumn>
        <Label
          $bgColor={ COLORS.dividingLines }
          $color={ COLORS.helpText }
        >
          { data.linkedContactType }
        </Label>
      </TypeColumn>

      { data.linkedContactType === LINKED_ITEM_TYPES.task &&
        <>
          <StageColumn>
            <TextWithTooltip text={ data.stage ?? '-' }/>
          </StageColumn>
          <AssignedToColumn>
            <TextWithTooltip text={ getFullName(data.clientData) ?? '-' }/>
          </AssignedToColumn>
          <AmountColumn>
            <TextWithTooltip
              text={ dueDate }
            />
          </AmountColumn>
        </>
      }
      
      { (data.linkedContactType === LINKED_ITEM_TYPES.account ||
        data.linkedContactType === LINKED_ITEM_TYPES.policy) &&
        <>
          <StageColumn>
            <TextWithTooltip text={ data.institutionName ?? '-' }/>
          </StageColumn>
          <StatusColumn>
            <TextWithTooltip text={ data.accountNumber ? `#${data.accountNumber}` : '-' }/>
          </StatusColumn>
          <AmountColumn>
            <TextWithTooltip text={ accountAndPolicyAmount }/>
          </AmountColumn>
        </>
      }

      { data.linkedContactType === LINKED_ITEM_TYPES.opportunity &&
        <>
          <StageColumn>
            <TextWithTooltip text={ data.saleType ?? '-' }/>
          </StageColumn>
          <StatusColumn>
            { data.opportunityStatus ? 
              <SalesClientOpportunitiesStatusOption
                label={ data.opportunityStatus }
                value={ data.opportunityStatus }
              /> : '-'
            }
          </StatusColumn>
          <AmountColumn>
            <TextWithTooltip text={ salesCycleAndOpportunityAmount }/>
          </AmountColumn>
        </>
      }

      { data.linkedContactType === LINKED_ITEM_TYPES.plan &&
        <>
          <StageColumn>
            <TextWithTooltip 
              text={ data.startDate ? getUTCFormattedString(data.startDate, MONTH_DAY_YEAR_BACKSLASH) : '-' }
            />
          </StageColumn>
          <StatusColumn>
            <TextWithTooltip text={ data.feeStatus ?? '-' }/>
          </StatusColumn>
          <AmountColumn>
            <TextWithTooltip text={ feeAmount }/>
          </AmountColumn>
        </>
      }

      { data.linkedContactType === LINKED_ITEM_TYPES.salesCycle &&
        <>
          <StageColumn>
            <TextWithTooltip text={ data.stage ?? '-' }/>
          </StageColumn>
          <StatusColumn>
            { data.salesCycleStatus ?
              <SaleCycleStatusOption
                label={ data.salesCycleStatus }
                value={ data.salesCycleStatus }
              /> : '-'
            }
          </StatusColumn>
          <AmountColumn>
            <TextWithTooltip text={ salesCycleAndOpportunityAmount }/>
          </AmountColumn>
        </>
      }
    </>
  );
};
