export const CONTACT_NOTES_AND_HISTORY_INPUT_NAMES = {
  communicateWithContact: 'communicateWithContact',
  linkedItem: 'linkedItem',
  linkedItemId: 'linkedItemId',
  noteContactId: 'noteContactId',
  noteId: 'noteId',
  notes: 'contactNotes',
  noteText: 'noteText',
  noteType: 'noteType',
  tagId: 'tagId',
  tags: 'tags',
  tagValue: 'value',
  title: 'title',
} as const;
