import {
  AccountPlansNotesFieldsNames,
  AccountPolicyValuesFieldsNames,
  BalanceHistoryFieldsNames,
  BalanceHistoryItemFieldsNames,
  BeneficiariesItemFieldsNames, BeneficiariesTypeNames, CommonTypeFieldsNames,
  GeneralBasicInformationTypeFieldsNames,
  NoteItemFieldsNames,
  PlanTypeFieldsNames,
  StandingInstructionsItemFieldsNames,
  StandingInstructionsTypeNames,
  TagItemFieldsNames
} from '@modules/AccountsAndPlansPopup/types';

export const COMMON_FIELD_NAMES: CommonTypeFieldsNames = {
  accountPlansNotes: 'accountPlansNotes',
  accountType: 'accountType',
  contactId: 'contactId',
  name: 'name',
};

export const STANDING_INSTRUCTIONS_ITEM_FIELDS_NAMES: StandingInstructionsItemFieldsNames = {
  amount: 'amount',
  endDate: 'endDate',
  sort: 'sort',
  startDate: 'startDate',
  type: 'type',
};

export const STANDING_INSTRUCTIONS_FIELDS_NAMES: StandingInstructionsTypeNames = {
  standingInstructions: 'standingInstructions',
  standingInstructionsNotes: 'standingInstructionsNotes',
};

export const NOTES_FIELDS_ITEM_NAMES: NoteItemFieldsNames = {
  communicateWithContact: 'communicateWithContact',
  noteContactId: 'noteContactId',
  noteId: 'noteId',
  noteText: 'noteText',
  noteType: 'noteType',
  tags: 'tags',
  title: 'title',
};

export const NOTES_NAMES: AccountPlansNotesFieldsNames = {
  accountPlansNotes: 'accountPlansNotes'
};

export const TAG_FIELDS_ITEM_NAMES: TagItemFieldsNames = {
  id: 'id',
  value: 'value'
};

export const BENEFICIARIES_ITEM_NAMES: BeneficiariesItemFieldsNames = {
  name: 'name',
  percent: 'percent',
  relationType: 'relationType',
  sort: 'sort',
  type: 'type',
};

export const BENEFICIARIES_NAMES: BeneficiariesTypeNames = {
  beneficiaries: 'beneficiaries',
};

export const BALANCE_HISTORY_ITEM_NAMES: BalanceHistoryItemFieldsNames = {
  newBusinessAmount: 'newBusinessAmount',
  sort: 'sort',
  type: 'type',
  updated: 'updated',
  value: 'value',
};

export const BALANCE_HISTORY_NAMES: BalanceHistoryFieldsNames = {
  balanceHistory: 'balanceHistory'
};

export const ACCOUNT_POLICY_VALUES_NAMES: AccountPolicyValuesFieldsNames = {
  cashValue: 'cashValue',
  deathBenefit: 'deathBenefit',
  incomeValue: 'incomeValue',
  surrenderValue: 'surrenderValue',
};

export const BASIC_INFORMATION_FIELDS_NAMES: GeneralBasicInformationTypeFieldsNames = {
  accountNumber: 'accountNumber',
  applicationDate: 'applicationDate',
  beneficiaries: 'beneficiaries',
  deadlineDate: 'deadlineDate',
  deliveryDate: 'deliveryDate',
  effectiveDate: 'effectiveDate',
  heldAwayStatus: 'heldAwayStatus',
  institutionName: 'institutionName',
  managedStatus: 'managedStatus',
  productModel: 'productModel',
  registrationType: 'registrationType',
  reviewDate: 'reviewDate',
  status: 'status',
  subTypeCashEquivalent: 'subTypeCashEquivalent',
  subTypeAnnuities:  'subTypeAnnuities',
  subTypeInvestments: 'subTypeInvestments',
  submittedDate: 'submittedDate',
  taxQualification: 'taxQualification',
  type: 'type',
};

export const PLAN_BASIC_INFORMATION_FIELDS_NAMES: PlanTypeFieldsNames = {
  basicPlanDescription: 'basicPlanDescription',
  completionDate: 'completionDate',
  feeAmount: 'feeAmount',
  feeFrequency: 'feeFrequency',
  feeStatus: 'feeStatus',
  startDate: 'startDate',
};
