import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useWatch } from 'react-hook-form';

import { getContactSalesPipelineOpportunitiesAccountPlans } from '@services/api/common/common';
import { getFilterParams } from '@/shared/utils/filterUtils';
import {
  getAdditionalParamsToNameParam,
  responseLinkedItemDataConverter
} from '@/shared/utils/fetchAutocompleteUtils';

import { LINKED_ITEM_PARAM_NAMES, TypeFilter, TYPE_FILTER_OPTIONS } from '@modules/NotesAndHistory/data';
import { LINKED_ITEM_TYPES } from '@constants/linkedItem';

import { OpportunityStatus } from '@modules/LinkedItem/components';
import CustomSelect from '@components/CustomSelect';
import FormBlock from '@components/FormBlock';
import HFLinkedItem from '@modules/HookFormComponents/HFLinkedItem';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip/Tooltip';

import { Divider, Wrapper } from './styles';

import { GeneralAdditionalPropsType } from './types';
import HFHiddenInput from '@/modules/HookFormComponents/HFHiddenInput';

const ELLIPSIS_TEXT_WRAPPER_STYLE = {
  minHeight: '14px'
};

const SELECT_PROPS = {
  style: {
    width: 'auto',
  }
};

const INPUT_PROPS = {
  maxlength: 150,
  defaultValue: '',
};

const TEXT_FIELD_PROPS = {
  style: {
    minWidth: 50
  }
};


export const GeneralAdditionalProps = ({
  control,
  currentLinkedContact,
  initLinkedItem,
  instanceStore,
  names,
  noteContactOptions,
  onEntityClick,
  onLinkedItemChange,
  setValue,
}: GeneralAdditionalPropsType) => {

  const { resetState } = instanceStore;

  const [contactId, linkedItemId, linkedItem, communicateWithContact] = useWatch({
    control,
    name: [
      names.noteContactId,
      names.linkedItemId,
      names.linkedItem,
      names.communicateWithContact
    ]
  });

  const ref = useRef(null);
  const wrapperRef = useRef(null);

  const [item, setItem] = useState('All');
  const [wrapperWidth, setWrapperWidth] = useState(500);
  const [disabled, setDisabled] = useState(false);

  const responseConverter = useCallback((response: AxiosResponse) => {
    let contact = currentLinkedContact;
    if(communicateWithContact !== currentLinkedContact.id && currentLinkedContact.type === 'Household') {
      contact = currentLinkedContact.householderContacts
        .filter(member => member.contactId === communicateWithContact)[0];
    }
    const contactData = responseLinkedItemDataConverter(response.data.data);
    return contactData.map((obj: any) => {
      obj.contactData = contact;
      obj.linkedContactType = obj.accountType || obj.linkedContactType;
      return obj;
    });
  }, [item, currentLinkedContact, communicateWithContact]);

  const getParams = useMemo(() => {
    const filterParams = item === TypeFilter.all || !item ?
      Object.values(TypeFilter).filter(type => type !== TypeFilter.all) :
      [item];
    const typeFilter = getFilterParams({
      [LINKED_ITEM_PARAM_NAMES.types]: filterParams,
    });
    return getAdditionalParamsToNameParam({
      [LINKED_ITEM_PARAM_NAMES.id]: communicateWithContact,
      ...typeFilter,
    });
  }, [item, communicateWithContact]);

  const autocompletePlaceholder = useMemo(() => {
    if(!item || item === TypeFilter.all) {
      return 'Linked Item. Start Typing to Search...';
    }
    return item;
  }, [item]);

  const onSelectChange = useCallback((e: any) => {
    setDisabled(false);

    const { value } = e.target;
    setItem(value);

    if(ref?.current) {
      setTimeout(() => {
        //@ts-ignore
        ref.current.focus();
        setDisabled(value === TypeFilter.opportunity || value === TypeFilter.salesCycle);
      }, 0);
    }
  }, [setDisabled, setItem, ref]);

  const getWithoutQueryMode = useMemo(() => communicateWithContact, [communicateWithContact]);

  const onEntityClickWrapper = useCallback(() => {
    const entityName = linkedItem?.linkedContactType;
    resetState();
    if(onEntityClick && linkedItemId && entityName) {
      onEntityClick(linkedItemId, entityName);
    }
  }, [onEntityClick, resetState, linkedItemId, linkedItem]);
  
  const additionalUserContent = useMemo(() => {
    if(initLinkedItem?.linkedContactType === LINKED_ITEM_TYPES.opportunity) {
      return <OpportunityStatus data={ initLinkedItem }/>;
    }
    return null;
  }, [initLinkedItem]);

  useEffect(() => {
    if(!contactId) {
      setValue(names.linkedItemId, null);
    }
  }, [contactId]);

  useEffect(() => {
    if(wrapperRef && wrapperRef.current) {
      //@ts-ignore
      const width = wrapperRef.current.getBoundingClientRect().width;
      setWrapperWidth(width);
    }
  }, [wrapperRef]);

  return (
    <FormBlock isBorderBottom marginBottom={ 15 }>
      <FormBlock.RowWrapper
        style={ {
          gap: 25
        } }
      >
        <FormBlock.FormField label={ 'Note Title' } $labelWidth={ 90 }>
          <HFTextInput
            inputProps={ INPUT_PROPS }
            TextFieldProps={ TEXT_FIELD_PROPS }
            control={ control }
            disableErrorStyle={ true }
            name={ names.title }
            placeholder='Enter Note Title'
            showErrorText={ false }
          />
        </FormBlock.FormField>

        <FormBlock.FormField label={ 'With' } $labelWidth={ 55 }>
          <HFSelect
            SelectProps={ SELECT_PROPS }
            control={ control }
            name={ names.communicateWithContact }
            options={ noteContactOptions }
            placeholder='Select Contact'
            renderItem={ (data: any) => {
              const valueToRender = data?.label || ' ';
              return (
                <Tooltip
                  displayOnlyOnOverflow={ true }
                  title={ valueToRender }
                >
                  <TextEllipsis
                    $maxWidth={ 250 }
                    style={ ELLIPSIS_TEXT_WRAPPER_STYLE }
                  >
                    { valueToRender }
                  </TextEllipsis>
                </Tooltip>
              );
            } }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>

      <FormBlock.RowWrapper>
        <FormBlock.FormField label={ 'Linked Item' } $labelWidth={ 90 }>
          <Wrapper $isBorder={ !linkedItemId } disabled={ !communicateWithContact } ref={ wrapperRef }>
            <HFHiddenInput
              control={ control }
              name={ names.linkedItem }
              defaultValue={ null }
            />
            { !linkedItemId && 
              <>
                <CustomSelect
                  disabled={ !contactId }
                  onChange={ onSelectChange }
                  options={ TYPE_FILTER_OPTIONS }
                  value={ item }
                  style={ {
                    width: 110,
                    fontSize: 12,
                  } }
                />
                <Divider/>
              </>
            }
            <HFLinkedItem
              AutocompleteProps={ {
                filterOptions: (options: any) => options,
                getOptionLabel: (option: any) => option.name,
                openOnFocus: true,
                clearOnBlur: true
              } }
              PopperStyledProps={ {
                width: wrapperWidth
              } }
              FetchProps={ {
                getParams: getParams,
                requestSender: getContactSalesPipelineOpportunitiesAccountPlans,
                responseConverter,
              } }
              TextFieldProps={ {
                inputRef: ref,
                placeholder: autocompletePlaceholder,
              } }
              UserItemProps={ {
                $isClickable: true,
                additionalUserContent,
                onClick: onEntityClickWrapper
              } }
              inputProps={ {
                readOnly: !contactId || disabled
              } }
              control={ control }
              isEntity={ true }
              initLinkedContact={ initLinkedItem || null }
              name={ names.linkedItemId }
              onLinkedContactChange={ onLinkedItemChange }
              refreshData={ true }
              withoutQueryMode={ getWithoutQueryMode }
            />
          </Wrapper>
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};
